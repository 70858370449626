import React, { useMemo } from 'react';
import { IInjector } from '../Abstractions/IInjector';

export interface ModuleContextProps {
  injector: IInjector;
  children?: React.ReactNode;
}

export function InjectorContext(props: ModuleContextProps) {
  const { injector } = props;

  const contextValue = useMemo(() => ({ injector }), [injector]);

  return <__context.Provider value={contextValue}>{props.children}</__context.Provider>;
}

export interface InjectorContextValue {
  injector: IInjector | undefined;
}

export const __context = React.createContext<InjectorContextValue>({
  injector: undefined,
});
