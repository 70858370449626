import { IInjector } from '../Abstractions/IInjector';
import { Module } from '../Abstractions/Module';
import { Provider } from '../Abstractions/Provider';
import { ContainerAdapter } from './ContainerAdapter';

export function createInjector(module: Module, parent?: IInjector): IInjector {
  const container = parent?.createChildContainer() ?? ContainerAdapter.root();

  for (const item of collectProviders(module)) {
    container.register(item);
  }

  return container.build();
}

/**
 * CollectProviders should return a flat list of providers suitable
 * for registering in a dependency container.
 * The idea is to collect the unique set of modules referenced by the
 * given module's imports to produce a list of providers configured.
 *
 * The list of providers should be ordered by their appearence in the
 * dependency graph where the root module's providers appear first
 * in the output list and the given module's providers appear last.
 *
 * The provider list ordering is important because the dependency
 * container applies a last-in-wins approach to dependency registration
 * meaning that the last registration of a given InjectionToken will be
 * the one that's used when injector.resolve() is called.
 */
function collectProviders(module: Module): Provider[] {
  const modules = collectUniqueModules(module);
  return modules.flatMap((m) => m.providers);
}

/**
 * CollectUniqueModules takes a given module and returns
 * a list of unique modules (see `collectAllModules()`).
 * The list is made unique by using the a `Set` which works
 * because module objects must be unique references within
 * a module graph (i.e. they are never clones or copies) so
 * we can assume two modules are the same by checking if `m1 === m2`.
 */
function collectUniqueModules(module: Module): Module[] {
  return [...new Set(collectAllModules(module))];
}

/**
 * collectAllModules returns all modules in the import graph
 * ordered starting with the top most modules down to the
 * given module.
 */
function collectAllModules(module: Module): Module[] {
  return module.imports.flatMap((m) => collectAllModules(m)).concat([module]);
}
