import { useInjection } from 'libs/uikit/ioc/React';
import {
  ArrowRightIcon,
  Button,
  Chip,
  ChipColor,
  ContentLoadingIndicator,
  Flex,
  H1,
  Now,
  Paper,
  Temporal,
  TextInput,
  isBefore,
  toInstant,
  useAsync,
} from 'libs/uikit/lib';
import { first } from 'lodash-es';
import React from 'react';
import { Link } from 'react-router-dom';
import { MarketplaceListItem } from 'services/web/protos/ExhibitorPortal/ExhibitorEvents_pb';
import { AppShell } from '../Components/AppShell';
import { EventTitleBar } from '../Components/EventTitleBar';
import { ExhibitorEventsClient, ExhibitorsClient } from '../Modules/Api/Clients';
import { toZonedDateTime } from '../Util/ToZonedDateTime';

export function EventListPage() {
  const exhibitorsClient = useInjection(ExhibitorsClient);
  const exhibitorEventsClient = useInjection(ExhibitorEventsClient);

  const exhibitor = useAsync(async () => {
    const response = await exhibitorsClient.listMyExhibitors({});
    return first(response.items);
  });

  const events = useAsync(async () => {
    if (!exhibitor.data?.exhibitorId) {
      return undefined;
    }
    return await exhibitorEventsClient.listMarketplaces({
      exhibitorId: exhibitor.data.exhibitorId,
      pageSize: 10,
    });
  }, [exhibitor.data?.exhibitorId]);

  return (
    <AppShell>
      <Flex gap={1} align="stretch">
        <Flex horizontal justify="space-between" align="flex-end">
          <H1>Your events</H1>
          <Flex horizontal gap={0.25}>
            <Chip label="All" color="dark-blue" />
            <Chip label="Upcoming" color="white" />
            <Chip label="Past" color="white" />
          </Flex>
        </Flex>

        <Paper elevation={1}>
          <TextInput name="search" placeholder="Search" scale={2} />
        </Paper>

        <ContentLoadingIndicator loading={[exhibitor, events]}>
          {!events.data?.items.length && <div>no content</div>}

          <Flex gap={1} align="stretch">
            {events.data?.items.map((marketplace) => (
              <EventCard key={marketplace.marketplaceId} marketplace={marketplace} />
            ))}
          </Flex>
        </ContentLoadingIndicator>
      </Flex>
    </AppShell>
  );
}

interface EventCardProps {
  marketplace: MarketplaceListItem;
}

function EventCard(props: EventCardProps) {
  const marketplace = props.marketplace;

  // assuming we always have start/end times which may not be correct.
  const startsAt = toZonedDateTime(marketplace.startsAt, marketplace.timezone)!;
  const endsAt = toZonedDateTime(marketplace.endsAt, marketplace.timezone)!;
  const timeDistance = calculateTimeDistance(startsAt, endsAt);

  return (
    <Paper elevation={1}>
      <Flex gap={1} align="stretch">
        <Flex horizontal gap={0.5} justify="space-between">
          <EventTitleBar
            name={marketplace.name}
            startsAt={startsAt}
            endsAt={endsAt}
            image={marketplace.image}
            variant="list-item"
          />
          <Chip label={timeDistance.label} color={timeDistance.color} />
        </Flex>

        <Link to={`/events/${marketplace.marketplaceId}`} style={{ marginTop: 'auto' }}>
          <Button scale={1} iconRight={ArrowRightIcon} primary>
            Manage event
          </Button>
        </Link>
      </Flex>
    </Paper>
  );
}

/**
 * helper function that calculates a label and chip color for an event's list item
 * based on the start/end time.
 */
function calculateTimeDistance(
  startsAt: Temporal.ZonedDateTime,
  endsAt: Temporal.ZonedDateTime
): { label: string; color: ChipColor } {
  const now = Now.instant();

  // if the event is starting in the future
  if (isBefore(now, startsAt.toInstant())) {
    const distance = now.until(startsAt.toInstant());

    const distanceInMonths = Math.abs(
      distance.total({
        unit: 'months',
        relativeTo: now.toZonedDateTimeISO(startsAt.timeZoneId),
      })
    );

    const distanceInDays = Math.abs(
      distance.total({
        unit: 'days',
        relativeTo: now.toZonedDateTimeISO(startsAt.timeZoneId),
      })
    );

    if (distanceInMonths >= 2) {
      return { label: `${Math.floor(distanceInMonths)} months away`, color: 'grey' };
    }

    if (distanceInDays >= 2) {
      return { label: `${Math.floor(distanceInDays)} days away`, color: 'grey' };
    }

    return { label: 'Upcoming', color: 'grey' };
  }

  // if the event started in the past but ends in the future
  if (isBefore(now, endsAt.toInstant())) {
    return { label: 'Live', color: 'green' };
  }

  // else the event has ended
  return { label: 'Past event', color: 'dark-blue' };
}
