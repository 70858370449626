import { IOpenIDConnectDevelopment } from './IOpenIDConnectConfig';
import { IOpenIDConnectHandler, OpenIDConnectUser, RedirectState } from './IOpenIDConnectHandler';
import { TypedEvent } from './TypedEventEmitter';

export class DevelopmentOpenIDConnectHandler implements IOpenIDConnectHandler {
  constructor(private config: IOpenIDConnectDevelopment) {}

  currentUserChanged = new TypedEvent<OpenIDConnectUser | undefined>();

  private developmentUser?: OpenIDConnectUser;

  currentUser(): Promise<OpenIDConnectUser | undefined> {
    return Promise.resolve(this.developmentUser);
  }

  signinRedirect(state?: RedirectState): Promise<void> {
    // An OpenIDConnect server would redirect the browser to
    // the `redirectUri` route after signing-in the user's session
    let url = this.config.redirectUri;

    if (state?.returnUrl) {
      url += `?state.returnUrl=${encodeURIComponent(state.returnUrl)}`;
    }

    window.location.assign(url);

    return Promise.resolve();
  }

  signinCallback(): Promise<RedirectState> {
    this.developmentUser = {
      subject: 'development',
      accessToken: '?',
    };

    this.currentUserChanged.emit(this.developmentUser);

    const returnUrl = new URLSearchParams(window.location.search).get('state.returnUrl');
    if (typeof returnUrl === 'string') {
      return Promise.resolve({ returnUrl });
    }

    return Promise.resolve({});
  }

  signoutRedirect(): Promise<void> {
    // An OpenIDConnect server would redirect the browser to
    // the `postLogoutRedirectUri` route after signing-out the
    // users session.
    window.location.assign(this.config.postLogoutRedirectUri);
    return Promise.resolve();
  }

  signoutCallback(): Promise<void> {
    this.developmentUser = undefined;
    this.currentUserChanged.emit(undefined);
    return Promise.resolve();
  }
}
