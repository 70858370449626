/**
 * The purpose of the DefaultAnimations are to setup a set of constants that
 * developers should choose from when implementing animations in the UIKit
 * or in applications.
 *
 * The intention is to avoid developers having to make ad-hoc decisions about
 * animation timing functions and fiddling with the numbers.
 *
 * Ideally animation timing should be consistent over the UI/UX and these constants
 * will hopefully help us achieve this.
 */

import { Spring } from 'framer-motion';

export const springQuick: Spring = {
  type: 'spring',
  duration: 0.2,
  bounce: 0.5,
};

export const springDefault: Spring = {
  type: 'spring',
  duration: 0.5,
  bounce: 0.3,
};

export const springSlow: Spring = {
  type: 'spring',
  duration: 1,
  bounce: 0.2,
};

export const springDebugging: Spring = {
  type: 'spring',
  duration: 10,
  bounce: 0.2,
};
