import { Code, GrpcWebError, Interceptor, Metadata, UnaryCall } from 'libs/grpckit/src';
import { tryGetTraceId } from './GrpcWebError';

export class ErrorInterceptor implements Interceptor {
  async unary<TRequest, TResponse>(
    descriptor: unknown,
    request: TRequest,
    metadata: Metadata,
    signal: AbortSignal,
    next: UnaryCall<TRequest, TResponse>
  ): Promise<TResponse> {
    try {
      return await next(descriptor, request, metadata, signal);
    } catch (error) {
      if (error instanceof GrpcWebError) {
        console.error(`rpc error: ${error.message} (${tryGetTraceId(error)})`);

        if (error.code === Code.Unknown) {
          if (error.message === 'Response closed without headers') {
            error.message = 'Network connection error.';
          }
        }
      }

      throw error;
    }
  }
}
