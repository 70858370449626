import { macroTokenToFormatOpts } from './Formats';
import { Intl as TemporalIntl, Temporal, browserLocale } from './Temporal';

export type DateParts = {
  day: string;
  month: string;
  year: string;
};

/**
 * Formats the given datetime using the supplied format string.
 * The format string supports the CLDR:
 * https://cldr.unicode.org/translation/date-time/date-time-symbols
 */
export function formatTemporal(
  dateTimeLike: Temporal.ZonedDateTime | Temporal.PlainDateTime | Temporal.PlainDate | Temporal.PlainTime,
  formatstr: string,
  locale?: string
): string {
  if (!dateTimeLike) {
    return '';
  }

  if (!locale) {
    locale = browserLocale() ?? 'en';
  }

  const formatter = new Formatter(locale);

  return formatter.format(dateTimeLike, formatstr);
}

export type FormattableDateTimeLike =
  | Temporal.ZonedDateTime
  | Temporal.PlainDateTime
  | Temporal.PlainDate
  | Temporal.PlainTime;

class Formatter {
  constructor(private locale: string) {}

  format(dateTimeLike: FormattableDateTimeLike, formatstr: string) {
    return this.stringifyTokens(parseFormat(formatstr), this.tokenToString(dateTimeLike));
  }

  private dateTimeFormat(
    dateTimeLike: FormattableDateTimeLike,
    opts: TemporalIntl.DateTimeFormatOptions,
    extract: Intl.DateTimeFormatPartTypes
  ) {
    if (dateTimeLike instanceof Temporal.ZonedDateTime) {
      dateTimeLike = dateTimeLike.toPlainDateTime(); // ZonedDateTime can't be formatted by Intl for some reason (throws).
    }

    const extracted = new TemporalIntl.DateTimeFormat(this.locale, opts)
      .formatToParts(dateTimeLike)
      .find((part) => part.type === extract);

    return extracted?.value ?? '';
  }

  private maybeMacro(token: Token): string {
    const opts = macroTokenToFormatOpts(token.val);
    if (opts) {
      return new TemporalIntl.DateTimeFormat(this.locale, opts).format();
    }
    return token.val;
  }

  private tokenToString(dateTimeLike: FormattableDateTimeLike) {
    // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: yeah, turns out mapping all the CLDR symbols is complex lol.
    return (token: Token) => {
      // Where possible: https://cldr.unicode.org/translation/date-time/date-time-symbols
      switch (token.val) {
        // ms
        case 'S':
          return 'millisecond' in dateTimeLike ? numberFormatter(dateTimeLike.millisecond) : '';
        case 'u':
        // falls through
        case 'SSS':
          return 'millisecond' in dateTimeLike ? numberFormatter(dateTimeLike.millisecond, 3) : '';
        // seconds
        case 's':
          return 'second' in dateTimeLike ? numberFormatter(dateTimeLike.second) : '';
        case 'ss':
          return 'second' in dateTimeLike ? numberFormatter(dateTimeLike.second, 2) : '';
        // fractional seconds
        case 'uu':
          return 'millisecond' in dateTimeLike ? numberFormatter(Math.floor(dateTimeLike.millisecond / 10), 2) : '';
        case 'uuu':
          return 'millisecond' in dateTimeLike ? numberFormatter(Math.floor(dateTimeLike.millisecond / 100)) : '';
        // minutes
        case 'm':
          return 'minute' in dateTimeLike ? numberFormatter(dateTimeLike.minute) : '';
        case 'mm':
          return 'minute' in dateTimeLike ? numberFormatter(dateTimeLike.minute, 2) : '';
        // hours
        case 'h':
          return 'hour' in dateTimeLike
            ? numberFormatter(dateTimeLike.hour % 12 === 0 ? 12 : dateTimeLike.hour % 12)
            : '';
        case 'hh':
          return 'hour' in dateTimeLike
            ? numberFormatter(dateTimeLike.hour % 12 === 0 ? 12 : dateTimeLike.hour % 12, 2)
            : '';
        case 'H':
          return 'hour' in dateTimeLike ? numberFormatter(dateTimeLike.hour) : '';
        case 'HH':
          return 'hour' in dateTimeLike ? numberFormatter(dateTimeLike.hour, 2) : '';
        // offset
        case 'Z':
          // like +6
          return 'offsetNanoseconds' in dateTimeLike ? formatOffset(dateTimeLike.offsetNanoseconds, 'narrow') : '';
        case 'ZZ':
          // like +06:00
          return 'offsetNanoseconds' in dateTimeLike ? formatOffset(dateTimeLike.offsetNanoseconds, 'short') : '';
        case 'ZZZ':
          // like +0600
          return 'offsetNanoseconds' in dateTimeLike ? formatOffset(dateTimeLike.offsetNanoseconds, 'techie') : '';
        case 'ZZZZ':
          // like EST
          return 'timeZoneId' in dateTimeLike ? formatTimeZone(dateTimeLike, this.locale, 'short') : '';
        case 'ZZZZZ':
          // like Eastern Standard Time
          return 'timeZoneId' in dateTimeLike ? formatTimeZone(dateTimeLike, this.locale, 'long') : '';
        // zone
        case 'z':
          // like America/New_York
          return 'timeZoneId' in dateTimeLike ? dateTimeLike.timeZoneId : '';
        // meridiems
        case 'a':
          return this.dateTimeFormat(dateTimeLike, { hour: 'numeric', hourCycle: 'h12' }, 'dayPeriod');
        // dates
        case 'd':
          return this.dateTimeFormat(dateTimeLike, { day: 'numeric' }, 'day');
        case 'dd':
          return this.dateTimeFormat(dateTimeLike, { day: '2-digit' }, 'day');
        // weekdays - standalone
        case 'c':
          // like 1
          return 'dayOfWeek' in dateTimeLike ? numberFormatter(dateTimeLike.dayOfWeek) : '';
        case 'ccc':
          // like 'Tues'
          return this.dateTimeFormat(dateTimeLike, { weekday: 'short' }, 'weekday');
        case 'cccc':
          // like 'Tuesday'
          return this.dateTimeFormat(dateTimeLike, { weekday: 'long' }, 'weekday');
        case 'ccccc':
          // like 'T'
          return this.dateTimeFormat(dateTimeLike, { weekday: 'narrow' }, 'weekday');
        // weekdays - format
        case 'E':
          // like 1
          return 'dayOfWeek' in dateTimeLike ? this.dateTimeFormat(dateTimeLike, { weekday: 'short' }, 'weekday') : '';
        case 'EEE':
          // like 'Tues'
          return this.dateTimeFormat(dateTimeLike, { weekday: 'short' }, 'weekday');
        case 'EEEE':
          // like 'Tuesday'
          return this.dateTimeFormat(dateTimeLike, { weekday: 'long' }, 'weekday');
        case 'EEEEE':
          // like 'T'
          return this.dateTimeFormat(dateTimeLike, { weekday: 'narrow' }, 'weekday');
        // months - standalone
        case 'L':
          // like 1
          return this.dateTimeFormat(dateTimeLike, { month: 'numeric', day: 'numeric' }, 'month');
        case 'LL':
          // like 01, doesn't seem to work
          return this.dateTimeFormat(dateTimeLike, { month: '2-digit', day: 'numeric' }, 'month');
        case 'LLL':
          // like Jan
          return this.dateTimeFormat(dateTimeLike, { month: 'short' }, 'month');
        case 'LLLL':
          // like January
          return this.dateTimeFormat(dateTimeLike, { month: 'long' }, 'month');
        case 'LLLLL':
          // like J
          return this.dateTimeFormat(dateTimeLike, { month: 'narrow' }, 'month');
        // months - format
        case 'M':
          // like 1
          return this.dateTimeFormat(dateTimeLike, { month: 'numeric' }, 'month');
        case 'MM':
          // like 01
          return this.dateTimeFormat(dateTimeLike, { month: '2-digit' }, 'month');
        case 'MMM':
          // like Jan
          return this.dateTimeFormat(dateTimeLike, { month: 'short' }, 'month');
        case 'MMMM':
          // like January
          return this.dateTimeFormat(dateTimeLike, { month: 'long' }, 'month');
        case 'MMMMM':
          // like J
          return this.dateTimeFormat(dateTimeLike, { month: 'narrow' }, 'month');
        // years
        case 'y':
          // like 2014
          return this.dateTimeFormat(dateTimeLike, { year: 'numeric' }, 'year');
        case 'yy':
          // like 14
          return this.dateTimeFormat(dateTimeLike, { year: '2-digit' }, 'year');
        case 'yyyy':
          // like 0012
          return this.dateTimeFormat(dateTimeLike, { year: 'numeric' }, 'year');
        case 'yyyyyy':
          // like 000012
          return this.dateTimeFormat(dateTimeLike, { year: 'numeric' }, 'year');
        // eras
        case 'G':
          // like AD
          return this.dateTimeFormat(dateTimeLike, { era: 'short' }, 'era');
        case 'GG':
          // like Anno Domini
          return this.dateTimeFormat(dateTimeLike, { era: 'long' }, 'era');
        case 'GGGGG':
          return this.dateTimeFormat(dateTimeLike, { era: 'narrow' }, 'era');
        // case 'kk':
        //   return numberFormatter(dt.weekYear.toString().slice(-2), 2);
        // case 'kkkk':
        //   return numberFormatter(dt.weekYear, 4);
        // case 'W':
        //   return numberFormatter(dt.weekNumber);
        // case 'WW':
        //   return numberFormatter(dt.weekNumber, 2);
        // case 'n':
        //   return numberFormatter(dt.localWeekNumber);
        // case 'nn':
        //   return numberFormatter(dt.localWeekNumber, 2);
        // case 'ii':
        //   return numberFormatter(dt.localWeekYear.toString().slice(-2), 2);
        // case 'iiii':
        //   return numberFormatter(dt.localWeekYear, 4);
        // case 'o':
        //   return numberFormatter(dt.ordinal);
        // case 'ooo':
        //   return numberFormatter(dt.ordinal, 3);
        // case 'q':
        //   // like 1
        //   return numberFormatter(dt.quarter);
        // case 'qq':
        //   // like 01
        //   return numberFormatter(dt.quarter, 2);
        // case 'X':
        //   return numberFormatter(Math.floor(dt.ts / 1000));
        // case 'x':
        //   return numberFormatter(dt.ts);
        default:
          return this.maybeMacro(token);
      }
    };
  }

  private stringifyTokens(splits: Token[], tokenToString: (token: Token) => string): string {
    let s = '';
    for (const token of splits) {
      if (token.literal) {
        s += token.val;
      } else {
        s += tokenToString(token);
      }
    }
    return s;
  }
}

type Token = {
  literal: boolean;
  val: string;
};

// this function converts a format string like "dd MMM yyyy" into the individual
// tokens like "dd" and "MMM" as well as any literal characters like "hello world!" that may be in the format string.
// https://github.com/moment/luxon/blob/f257940093dca8efa976e142e0f33ed3620425ed/src/impl/formatter.js#L49
// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: yep this one is complicated and copied from an open-source project.
function parseFormat(fmt: string): Token[] {
  let current = null;
  let currentFull = '';
  let bracketed = false;
  const splits = [];
  for (let i = 0; i < fmt.length; i++) {
    const c = fmt.charAt(i);
    if (c === "'") {
      if (currentFull.length > 0) {
        splits.push({ literal: bracketed || /^\s+$/.test(currentFull), val: currentFull });
      }
      current = null;
      currentFull = '';
      bracketed = !bracketed;
    } else if (bracketed) {
      currentFull += c;
    } else if (c === current) {
      currentFull += c;
    } else {
      if (currentFull.length > 0) {
        splits.push({ literal: /^\s+$/.test(currentFull), val: currentFull });
      }
      currentFull = c;
      current = c;
    }
  }

  if (currentFull.length > 0) {
    splits.push({ literal: bracketed || /^\s+$/.test(currentFull), val: currentFull });
  }

  return splits;
}

function numberFormatter(value: number, padding = 0): string {
  return padStart(value, padding);
}

function formatOffset(offsetNanoseconds: number, format: 'short' | 'narrow' | 'techie') {
  const offset = offsetNanoseconds / (6 * (10 ^ 10));
  const hours = Math.trunc(Math.abs(offset / 60));
  const minutes = Math.trunc(Math.abs(offset % 60));
  const sign = offset >= 0 ? '+' : '-';

  switch (format) {
    case 'short':
      return `${sign}${padStart(hours, 2)}:${padStart(minutes, 2)}`;
    case 'narrow':
      return `${sign}${hours}${minutes > 0 ? `:${minutes}` : ''}`;
    case 'techie':
      return `${sign}${padStart(hours, 2)}${padStart(minutes, 2)}`;
    default:
      throw new RangeError(`Value format ${format} is out of range for property format`);
  }
}

function formatTimeZone(zonedDateTime: Temporal.ZonedDateTime, locale: string, format: 'long' | 'short'): string {
  const tzname = new TemporalIntl.DateTimeFormat(locale, { timeZone: zonedDateTime.timeZoneId, timeZoneName: format })
    .formatToParts(zonedDateTime.toInstant())
    .find((part) => part.type === 'timeZoneName');

  return tzname?.value ?? '';
}

function padStart(input: number, n = 2) {
  const isNeg = input < 0;
  let padded: string;
  if (isNeg) {
    padded = `-${-input}`.padStart(n, '0');
  } else {
    padded = `${input}`.padStart(n, '0');
  }
  return padded;
}
