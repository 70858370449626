import React, { Ref, SVGProps, forwardRef } from 'react';

export const AssetLogo = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Group 11"
      ref={ref}
      width="174.284"
      height="39.523"
      viewBox="0 0 174.284 39.523"
      {...props}
    >
      <defs>
        <linearGradient id="a" x1={0.029} x2={0.995} y1={0.5} y2={0.5} gradientUnits="objectBoundingBox">
          <stop offset={0} stopColor="#00aeef" />
          <stop offset={1} stopColor="#8ed8f8" />
        </linearGradient>
      </defs>
      <path
        fill="url(#a)"
        fillRule="evenodd"
        d="M6.416 8A13.743 13.743 0 0 1 9 3.953 12.378 12.378 0 0 1 17.575.047c6-.077 12.826-.522 17.493 4.056 5.665 5.557 3.441 15.192-1.7 20.263-3.913 3.858-9.455 5.538-14.843 5.7a7.144 7.144 0 0 1 .129 2c-.432 5.777-6.471 8.382-11.59 7.161a10.456 10.456 0 0 1-3.318-1.4 7.581 7.581 0 0 1-2.809-3.253C-.77 30.853.169 26.451 1.278 22.7 2.3 19.248 3.49 15.848 4.731 12.468 5.275 10.981 5.774 9.451 6.416 8Zm5.824 21.25a3.225 3.225 0 0 0-3.162 2.942 2.692 2.692 0 0 0 2.722 2.942 3.225 3.225 0 0 0 3.162-2.942 2.693 2.693 0 0 0-2.722-2.942Zm-6.585-9.117c-1.324 3.861-3.6 10.435-.793 13.8a4.315 4.315 0 0 0 1.808 1.332c-.456-.165-.67-1.477-.75-1.884a5.558 5.558 0 0 1 .827-4.189 6.734 6.734 0 0 1 3.289-2.462 12.1 12.1 0 0 1 4.185-.356 42.26 42.26 0 0 0 8.916-.589 16.35 16.35 0 0 0 6.592-2.878c4.8-3.628 7.656-12.289 2.327-16.808A13.437 13.437 0 0 0 24.3 3.416c-3.341-.261-7.108-.458-10.247.892a8.972 8.972 0 0 0-4.511 4.977c-1.505 3.462-2.569 7.133-3.832 10.689q-.026.081-.055.159Zm9.338-1.723h3.292a8.751 8.751 0 0 0 5.1-1.33 4.61 4.61 0 0 0 2.073-3.58 2.8 2.8 0 0 0-.737-2.208 4.773 4.773 0 0 0-3.65-1.217h-3.245Z"
        data-name="Path 1"
      />
      <g data-name="Group 3" fill="currentColor">
        <g data-name="Group 1">
          <path
            d="M134.495 16.918a5.113 5.113 0 0 1-2.915 4.164 7.917 7.917 0 0 1-2.45.572c-.2.016-2.386.011-2.386 0v5.667h-3.628V12.292h3.8c2.032 0 4-.084 5.817 1.008a3.668 3.668 0 0 1 1.762 3.618Zm-3.619.023v-.007c.028-1.362-1.46-1.712-2.54-1.712h-1.592v3.545h1.528c1.133 0 2.573-.486 2.604-1.826Z"
            data-name="Path 2"
          />
          <path
            d="M149.852 27.32h-4.032l-1.167-2.949h-5.815l-1.1 2.949h-3.949l6.3-15.026h3.526Zm-6.2-5.858-1.863-5.156-1.911 5.156Z"
            data-name="Path 3"
          />
          <path
            d="m161.568 13.758-2.314 2.441c.118-.127-1.024-.824-1.156-.892a3.03 3.03 0 0 0-2.924-.039c-1.187.772-.647 2 .366 2.581 1.278.74 2.872.736 4.116 1.537a4.407 4.407 0 0 1 .922 6.178c-1.841 2.668-6.1 2.67-8.7 1.224-.155-.086-1.914-1.126-1.792-1.246l2.377-2.42c-.127.132 1.256 1.029 1.408 1.108a3.315 3.315 0 0 0 3.122.154 1.42 1.42 0 0 0 .256-2.382c-2.05-1.607-5.478-1.303-6.334-4.254a4.381 4.381 0 0 1 2.727-5.05 7.746 7.746 0 0 1 6.972.37 10.23 10.23 0 0 1 .954.69Z"
            data-name="Path 4"
          />
          <path
            d="m174.283 13.758-2.314 2.441c.12-.127-1.04-.836-1.2-.915a3.316 3.316 0 0 0-2.451-.238c-.8.245-1.512 1.2-.951 2.012a1.808 1.808 0 0 0 .588.506c1.385.806 3.065.947 4.416 1.816a4.377 4.377 0 0 1 1.335 5.544c-1.505 3.1-5.821 3.267-8.614 2.123-.2-.079-2.452-1.353-2.291-1.517l2.377-2.418c-.116.12 1.122.947 1.269 1.031a3.959 3.959 0 0 0 2.468.515 2.269 2.269 0 0 0 1.022-.356 1.467 1.467 0 0 0 .025-2.309c-2.023-1.582-5.605-1.257-6.343-4.316a4.413 4.413 0 0 1 2.903-5.131 8.266 8.266 0 0 1 6.8.52 10.808 10.808 0 0 1 .961.692Z"
            data-name="Path 5"
          />
        </g>
        <g data-name="Group 2">
          <g data-name="Path 6">
            <path d="M55.248 27.268H45.76V12.483H55.003V14.003h-7.56v4.826H54.508v1.499H47.443v5.421h7.805V27.27Z" />
            <path d="M55.148 27.168V25.85h-7.805V20.225v.003h7.065v-1.3h-7.065v-5.026h7.56v-1.319H45.86v14.585h9.288m.2.2H45.66V12.383H55.103v1.72H47.543v4.626h7.065V20.428h-7.065v5.221H55.348v1.72Z" />
          </g>
          <g data-name="Path 7">
            <path d="M63.582 27.266h-1.705l-.024-.064-5.56-14.583-.052-.136h1.82l.024.065 4.665 12.471 4.665-12.471.025-.065h1.801l-.052.136-5.583 14.583-.024.064Z" />
            <path d="m56.386 12.583 5.56 14.583h1.567l5.583-14.583h-1.587L62.77 25.25h-.04L57.99 12.583h-1.605m-.29-.2h2.034l.049.13 4.571 12.22 4.572-12.22.048-.13h2.016l-.103.272L63.7 27.238l-.05.128H61.81l-.05-.129-5.56-14.583-.103-.271Z" />
          </g>
          <g data-name="Path 8">
            <path d="M80.687 27.268h-9.491V12.483H80.439V14.003h-7.558v4.826H79.946v1.499H72.881v5.421h7.806V27.27Z" />
            <path d="M80.587 27.168V25.85H72.78V20.225v.003h7.065v-1.3h-7.065v-5.026h7.558v-1.319h-9.043v14.585h9.29m.2.2H71.097V12.383H80.539v1.72h-7.558v4.626h7.065V20.428h-7.065v5.221h7.806v1.72Z" />
          </g>
          <g data-name="Path 9">
            <path d="M95.662 27.266h-2.008l-.03-.044-8.344-12.334v12.378H83.597V12.483h2.008l.03.044L93.98 24.74V12.483H95.662v14.783Z" />
            <path d="M83.697 12.583v14.583h1.483V14.622h.04l8.487 12.544h1.855V12.583H94.08v12.422h-.041l-8.487-12.422h-1.855m-.2-.2h2.161l.06.087 8.162 11.947V12.383h1.882V27.366h-2.161l-.06-.088-8.161-12.064v12.152h-1.883V12.383Z" />
          </g>
          <g data-name="Path 10">
            <path d="M104.417 27.266H102.734V14.002H97.853V12.483h11.446V14.003h-4.882v13.263Z" />
            <path d="M97.953 12.583v1.32h4.881v13.263h1.483V13.902h4.882v-1.319H97.953m-.2-.2H109.399v1.72H104.517v13.263h-1.883V14.102h-4.881V12.383Z" />
          </g>
          <g data-name="Path 11">
            <path d="M115.325 27.642c-1.192 0-2.406-.33-3.33-.907a12.249 12.249 0 0 1-.745-.608c-.672-.586-.676-.678-.678-.733a.117.117 0 0 1 .047-.1l1.274-.945.002-.002.122.159c.02-.016.023-.04.021-.05.046.204 1.358 1.19 1.54 1.278a3.6 3.6 0 0 0 1.56.338c1.051 0 2.043-.395 2.654-1.056.475-.513.695-1.166.638-1.888-.132-1.674-2.093-2.331-3.669-2.86-.347-.116-.674-.225-.959-.338-1.37-.546-2.41-1.76-2.71-3.167-.315-1.466.368-2.986 1.74-3.872.272-.175.558-.32.851-.431a5.739 5.739 0 0 1 2.043-.369c.774 0 1.556.154 2.263.445.018.007 1.9 1.068 1.949 1.399a.134.134 0 0 1-.049.126l-1.174.947-.126-.155c-.03.025-.032.061-.027.076-.027-.072-.33-.353-.675-.626-.587-.47-1.41-.74-2.256-.74-.593 0-1.145.135-1.597.39-1.082.607-1.826 2.058-1.142 3.344.489.92 1.588 1.276 2.558 1.59l.255.083c1.864.612 4.05 1.587 4.41 3.704.152.882-.02 1.866-.468 2.7-.403.75-1.018 1.332-1.828 1.727-.725.354-1.587.54-2.494.54Z" />
            <path d="M115.726 12.191c-.683 0-1.367.12-2.007.362a4.486 4.486 0 0 0-.833.422c-1.256.811-2.017 2.275-1.696 3.767.297 1.392 1.332 2.57 2.65 3.095 1.577.626 4.522 1.164 4.69 3.283.155 1.955-1.643 3.052-3.391 3.052a3.699 3.699 0 0 1-1.604-.348c-.147-.07-1.77-1.247-1.581-1.396-.04.031-1.275.946-1.275.946-.114.084 1.24 1.195 1.369 1.276.921.575 2.104.892 3.277.892 1.715 0 3.408-.677 4.233-2.215.427-.793.611-1.746.46-2.636-.357-2.091-2.562-3.04-4.344-3.626-1.04-.343-2.318-.681-2.87-1.721-.679-1.276-.025-2.802 1.18-3.479.486-.273 1.063-.401 1.647-.401.846 0 1.705.27 2.318.76.066.052.792.634.704.706l1.173-.946c.189-.153-1.764-1.31-1.875-1.356a5.863 5.863 0 0 0-2.225-.437M115.726 11.991a6.1 6.1 0 0 1 2.301.452c.076.031.566.32 1.003.603.97.629.991.77 1.007.874a.235.235 0 0 1-.085.22l-1.174.946-.252-.31c-.078.063-.073.16-.054.196-.042-.076-.332-.34-.648-.591-.561-.45-1.38-.717-2.193-.717-.576 0-1.111.13-1.548.376-1.041.584-1.758 1.977-1.103 3.21.47.885 1.55 1.234 2.501 1.542l.255.083c1.89.621 4.108 1.613 4.478 3.782.155.904-.02 1.912-.48 2.764-.412.77-1.042 1.365-1.87 1.77-.74.36-1.617.55-2.539.55-1.228 0-2.43-.327-3.383-.921-.079-.05-.434-.336-.758-.618-.705-.614-.708-.71-.712-.804a.219.219 0 0 1 .088-.184l1.271-.944.004-.002.243.317c.065-.052.068-.132.054-.161.094.21 1.297 1.12 1.49 1.22.444.215.968.328 1.517.328 1.023 0 1.987-.383 2.58-1.024.455-.492.666-1.12.612-1.812-.128-1.608-2.054-2.254-3.602-2.772-.348-.117-.676-.227-.964-.341-1.401-.559-2.463-1.8-2.77-3.24-.324-1.507.375-3.067 1.782-3.976.278-.179.571-.327.87-.44a5.839 5.839 0 0 1 2.08-.376Z" />
          </g>
        </g>
      </g>
    </svg>
  );
});
