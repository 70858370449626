/**
 * ITypedEvent<T> is a type-safe event emitter
 * for a single event.
 *
 * Callers are responsible for removing listeners
 * by using the return disposable or calling `off()`
 */
export interface ITypedEvent<T> {
  on(listener: Listener<T>): Disposable;
  once(listener: Listener<T>): void;
  off(listener: Listener<T>): void;
  emit(event: T): void;
}

export interface Listener<T> {
  (event: T): any;
}

export interface Disposable {
  dispose(): void;
}

export class TypedEvent<T> implements ITypedEvent<T> {
  private listeners: Listener<T>[] = [];
  private listenersOncer: Listener<T>[] = [];

  on(listener: Listener<T>): Disposable {
    this.listeners.push(listener);
    return {
      dispose: () => this.off(listener),
    };
  }

  once(listener: Listener<T>): void {
    this.listenersOncer.push(listener);
  }

  off(listener: Listener<T>) {
    const callbackIndex = this.listeners.indexOf(listener);
    if (callbackIndex > -1) {
      this.listeners.splice(callbackIndex, 1);
    }
  }

  emit(event: T) {
    /** Update any general listeners */
    for (const listener of this.listeners) {
      listener(event);
    }

    /** Clear the `once` queue */
    if (this.listenersOncer.length > 0) {
      const toCall = this.listenersOncer;
      this.listenersOncer = [];
      for (const listener of toCall) {
        listener(event);
      }
    }
  }
}
