import { Lifecycle, Module, provide } from 'libs/uikit/ioc';
import { AuthenticationStore, IAuthenticationStore } from './AuthenticationStore';
import { OpenIDConnectModule } from './OpenIDConnect/OpenIDConnectModule';
import { ISignInManager, SignInManager } from './SignInManager';

export const AuthenticationModule = new Module({
  imports: [OpenIDConnectModule],
  providers: [
    provide(IAuthenticationStore, AuthenticationStore, Lifecycle.Singleton),
    provide(ISignInManager, SignInManager, Lifecycle.Singleton),
  ],
});
