import { InjectionTokenLike } from '../Abstractions/InjectionToken';
import { getTsyringeToken } from './Mappers';
import * as tsyringe from './Tsyringe';

export function inject<T>(injectionToken: InjectionTokenLike<T>): ParameterDecorator {
  return tsyringe.inject(getTsyringeToken(injectionToken));
}

export function injectable(): ClassDecorator {
  return tsyringe.injectable() as ClassDecorator;
}
