import { Duration, Now } from 'libs/uikit/lib';
import React from 'react';
import { useParams } from 'react-router-dom';
import { AppShell } from '../../Components/AppShell';
import { EventManagementMenu } from '../../Components/EventManagementMenu';
import { EventTitleBar } from '../../Components/EventTitleBar';

const startsAt = Now.instant()
  .subtract(Duration.from({ hours: 5 * 24 }))
  .toZonedDateTimeISO('America/New_York');

const endsAt = Now.instant().toZonedDateTimeISO('America/New_York');

const image = require('../../Assets/Placeholder.svg');

export function LeadGenPage() {
  const { id } = useParams();

  return (
    <AppShell
      title={<EventTitleBar name="Example Event" startsAt={startsAt} endsAt={endsAt} image={image} />}
      menu={<EventManagementMenu />}
    >
      <div>id = {id}</div>
    </AppShell>
  );
}
