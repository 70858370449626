// @generated by protoc-gen-es v1.7.2 with parameter "keep_empty_files=true,target=js+dts"
// @generated from file services/web/protos/ExhibitorPortal/Exhibitors.proto (package ocs.exhibitors.protos, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message ocs.exhibitors.protos.ListMyExhibitorsRequest
 */
export const ListMyExhibitorsRequest = proto3.makeMessageType(
  "ocs.exhibitors.protos.ListMyExhibitorsRequest",
  [],
);

/**
 * @generated from message ocs.exhibitors.protos.ListMyExhibitorsResponse
 */
export const ListMyExhibitorsResponse = proto3.makeMessageType(
  "ocs.exhibitors.protos.ListMyExhibitorsResponse",
  () => [
    { no: 1, name: "items", kind: "message", T: ExhibitorListItem, repeated: true },
  ],
);

/**
 * @generated from message ocs.exhibitors.protos.ExhibitorListItem
 */
export const ExhibitorListItem = proto3.makeMessageType(
  "ocs.exhibitors.protos.ExhibitorListItem",
  () => [
    { no: 1, name: "exhibitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

