import { DrawerItem, DrawerItemProps } from 'libs/uikit/lib';
import React from 'react';
import { Link, useLinkClickHandler, useMatch, useResolvedPath } from 'react-router-dom';

interface Props extends DrawerItemProps {
  to: string;
}

export function RouteDrawerItem(props: Props) {
  const { to, ...rest } = props;

  // resolving the given link to get the full route id
  // so that we can test if the link is active or not.
  // an active link means we're on the page already.
  const resolved = useResolvedPath(props.to);
  const isActive = !!useMatch(resolved.pathname);

  // using a click handler for onMouseDown because it makes
  // the link feel very responsive when it doesn't need to
  // wait for the mouse to be released.
  const handleClick = useLinkClickHandler(to);

  return (
    <Link to={props.to} onMouseDown={handleClick} draggable={false}>
      <DrawerItem {...rest} active={isActive} />
    </Link>
  );
}
