import React, { useContext, useEffect, useState } from 'react';
import { IInjector } from '../Abstractions/IInjector';
import { Module } from '../Abstractions/Module';
import { createInjector } from '../Core/Injector';
import { InjectorContext, __context } from './InjectorContext';

export interface ModuleContextProps {
  module: Module;
  children?: React.ReactNode;
}

export function ModuleContext(props: ModuleContextProps) {
  const [injector, setInjector] = useState<IInjector>();

  const parentInjector = useContext(__context).injector;

  useEffect(() => {
    const currentInjector = createInjector(props.module, parentInjector);

    setInjector(currentInjector);

    return () => {
      // TODO: catch possible promise error
      currentInjector.dispose();
    };
  }, [parentInjector, props.module]);

  if (!injector) {
    return null;
  }

  return <InjectorContext injector={injector}>{props.children}</InjectorContext>;
}
