import { IContainer } from '../Abstractions/IContainer';
import { IInjector } from '../Abstractions/IInjector';
import { InjectionTokenLike } from '../Abstractions/InjectionToken';
import { ContainerAdapter } from './ContainerAdapter';
import { getTsyringeToken } from './Mappers';
import * as tsyringe from './Tsyringe';

export class InjectorAdapter implements IInjector {
  constructor(private dependencyContainer: tsyringe.DependencyContainer) {}

  resolve<T>(injectionToken: InjectionTokenLike<T>): T {
    return this.dependencyContainer.resolve(getTsyringeToken(injectionToken));
  }

  has<T>(injectionToken: InjectionTokenLike<T>): boolean {
    // Checking this container as well as all parent containers so that
    // the result of `injector.has(token)` correctly indicates if a `injector.resolve(token)`
    // will return a value.
    // The second parameter `true` tells tsyringe to check parent containers as well.
    return this.dependencyContainer.isRegistered(getTsyringeToken(injectionToken), true);
  }

  dispose(): void | Promise<void> {
    return Promise.resolve(this.dependencyContainer.dispose());
  }

  createChildContainer(): IContainer {
    const child = this.dependencyContainer.createChildContainer();
    return ContainerAdapter.childOf(child);
  }
}
