import { UIKitFonts } from 'libs/uikit/fonts/UIKitFonts';
import { Module, provideValue } from 'libs/uikit/ioc';
import { ModuleContext } from 'libs/uikit/ioc/React';
import { UIProvider } from 'libs/uikit/lib';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, FutureConfig, Navigate, Route, Routes } from 'react-router-dom';
import { ApiModule } from './Modules/Api/Clients';
import { AuthenticationGuard } from './Modules/Authentication/AuthenticationGuard';
import { AuthenticationModule } from './Modules/Authentication/AuthenticationModule';
import { AuthenticationRoutes } from './Modules/Authentication/AuthenticationRoutes';
import { IOpenIDConnectConfig } from './Modules/Authentication/OpenIDConnect/IOpenIDConnectConfig';
import { EventListPage } from './Pages/EventListPage';
import { EventDashboardPage } from './Pages/EventManagement/EventDashboardPage';
import { LeadGenPage } from './Pages/EventManagement/LeadGenPage';

const rrflags: Partial<FutureConfig> = { v7_relativeSplatPath: true };

const RootModule = new Module({
  imports: [AuthenticationModule, ApiModule],
  providers: [
    // // Local development mock
    // provideValue(IOpenIDConnectConfig, {
    //   type: 'development',
    //   redirectUri: `${location.origin}/helloworld/login/callback`,
    //   postLogoutRedirectUri: `${location.origin}/helloworld/logout/callback`,
    // }),

    // // Local identity service
    // provideValue(IOpenIDConnectConfig, {
    //   type: 'client',
    //   authority: 'http://localhost:9000',
    //   clientId: 'ep-exhibitor-portal',
    //   redirectUri: `${location.origin}/login/callback`,
    //   postLogoutRedirectUri: `${location.origin}/logout/callback`,
    // }),

    // Testing environment identity service
    provideValue(IOpenIDConnectConfig, {
      type: 'client',
      authority: 'https://identity.testing.cetdemo.com',
      clientId: 'ep-exhibitor-portal',
      redirectUri: `${location.origin}/login/callback`,
      postLogoutRedirectUri: `${location.origin}/logout/callback`,
    }),
  ],
});

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>Exhibitor Portal</title>
        <link rel="icon" href={require('./Assets/favicon.ico')} sizes="32x32" />
      </Helmet>
      <UIKitFonts />
      <UIProvider>
        <ModuleContext module={RootModule}>
          <BrowserRouter future={rrflags}>
            <Routes>
              <Route path="/" element={<Navigate to="/events" replace />} />
              <Route element={<AuthenticationGuard />}>
                <Route path="/events" element={<EventListPage />} />

                <Route path="/events/:id" element={<EventDashboardPage />} />
                <Route path="/events/:id/leadgen" element={<LeadGenPage />} />
                <Route path="/events/:id/traffic-builder" element={<div>traffic-builder</div>} />
                <Route path="/events/:id/tickets" element={<div>tickets</div>} />
                <Route path="/events/:id/integrations" element={<div>integrations</div>} />
                <Route path="/events/:id/credentials" element={<div>credentials</div>} />
                <Route path="/events/:id/credentials/order" element={<div>credentials/order</div>} />
              </Route>
              <Route path="*" element={null} />
            </Routes>
            <AuthenticationRoutes />
          </BrowserRouter>
        </ModuleContext>
      </UIProvider>
    </HelmetProvider>
  </React.StrictMode>
);
