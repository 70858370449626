import 'reflect-metadata';

export class InjectionToken<T> {
  // for internal use only
  readonly token: symbol;

  // unused member variable that references our generic <T>
  // so that there's something structural for typescript
  readonly __t?: T;

  constructor(private interfaceName: string) {
    this.token = Symbol(interfaceName);
  }

  toString() {
    return `InjectionToken<${this.interfaceName}>`;
  }
}

export type ClassType<T> = {
  new (...args: any[]): T;
};

export type InjectionTokenLike<T> = ClassType<T> | InjectionToken<T>;
