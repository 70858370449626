import { InjectionToken, inject, injectable } from 'libs/uikit/ioc';
import { computed, makeAutoObservable, makeObservable, observable, runInAction } from 'mobx';
import { IOpenIDConnectHandler } from './OpenIDConnect/IOpenIDConnectHandler';
import type { OpenIDConnectUser } from './OpenIDConnect/IOpenIDConnectHandler';

export interface IAuthenticationStore {
  /**
   * Ready indicates the loading state of the store.
   * If the store is not ready that means it is still attempting
   * to load a previous session.
   * If the store is ready that means it can be used but it does
   * not indicate that the there's an authenticated user.
   * The ready flag exists because the store must load a user session
   * from an async browser storage when the app starts up.
   */
  readonly ready: boolean;

  /**
   * IsAuthenticated signals that the store holds an authenticated session.
   * I.e. the user is authenticated.
   */
  readonly isAuthenticated: boolean;

  /**
   * CurrentToken is the authenticated user's current access token
   * or undefined if there's no authenticated user.
   */
  readonly currentToken: string | undefined;

  /**
   * Profile holds basic information about the authenticated user if available.
   */
  readonly profile: SimpleProfile | undefined;
}

export const IAuthenticationStore = new InjectionToken<IAuthenticationStore>('IAuthenticationStore');

export interface SimpleProfile {
  subject: string;
  givenName?: string;
  familyName?: string;
}

@injectable()
export class AuthenticationStore {
  @observable.ref
  private currentUser?: OpenIDConnectUser;

  @observable.ref
  ready = false;

  constructor(@inject(IOpenIDConnectHandler) oidc: IOpenIDConnectHandler) {
    makeObservable(this);

    oidc.currentUser().then((user) =>
      runInAction(() => {
        this.currentUser = user;
        this.ready = true;
      })
    );

    oidc.currentUserChanged.on((user) =>
      runInAction(() => {
        this.currentUser = user;
      })
    );
  }

  @computed
  get isAuthenticated(): boolean {
    return this.currentUser !== undefined;
  }

  @computed
  get currentToken(): string | undefined {
    return this.currentUser?.accessToken;
  }

  @computed
  get profile(): SimpleProfile | undefined {
    if (!this.currentUser) {
      return undefined;
    }

    return {
      subject: this.currentUser.subject,
      givenName: this.currentUser.givenName,
      familyName: this.currentUser.familyName,
    };
  }
}
