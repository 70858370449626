import { useInjection } from 'libs/uikit/ioc/React';
import { useAsync } from 'libs/uikit/lib';
import React, { useMemo } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { IOpenIDConnectConfig } from './OpenIDConnect/IOpenIDConnectConfig';
import { IOpenIDConnectHandler } from './OpenIDConnect/IOpenIDConnectHandler';

/**
 * AuthenticationRoutes can be added to the application root within a <BrowserRouter />
 * to connect the required client-side login/logout routes.
 */
export function AuthenticationRoutes() {
  const config = useInjection(IOpenIDConnectConfig);

  // The OpenIDConnect configuration requires the redirectUris
  // to be fully qualified but react-router expects route path's
  // to just be a url path string.
  // Here we're parsing out the path from the full urls.
  const loginPath = useMemo(() => new URL(config.redirectUri).pathname, [config]);
  const logoutPath = useMemo(() => new URL(config.postLogoutRedirectUri).pathname, [config]);

  return (
    <Routes>
      <Route path={loginPath} element={<LoginCallback />} />
      <Route path={logoutPath} element={<LogoutCallback />} />
      <Route path="*" element={null} />
    </Routes>
  );
}

export function LoginCallback() {
  const oidc = useInjection(IOpenIDConnectHandler);
  const navigate = useNavigate();

  useAsync(async () => {
    const state = await oidc.signinCallback();
    navigate(state.returnUrl ?? '/', { replace: true });
  }, []);

  return null;
}

export function LogoutCallback() {
  const oidc = useInjection(IOpenIDConnectHandler);
  const navigate = useNavigate();

  useAsync(async () => {
    await oidc.signoutCallback();
    navigate('/', { replace: true });
  }, []);

  return null;
}
