import { AssetLogo } from 'libs/uikit/assets';
import { useInjection } from 'libs/uikit/ioc/React';
import {
  AppBar,
  Box,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerPanel,
  DrawerSpacer,
  Flex,
  Icon,
  Text,
  Title,
  UserCircleIcon,
  capitalize,
} from 'libs/uikit/lib';
import { colors } from 'libs/uikit/lib/Color/Colors';
import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { IAuthenticationStore } from '../Modules/Authentication/AuthenticationStore';
import { ISignInManager } from '../Modules/Authentication/SignInManager';

interface Props {
  title?: React.ReactNode;
  menu?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode;
}

export function AppShell(props: Props) {
  const session = useInjection(ISignInManager);
  const authenticationStore = useInjection(IAuthenticationStore);

  return (
    <Flex horizontal style={{ position: 'relative', height: '100vh', overflow: 'hidden', background: colors.gray_100 }}>
      <Drawer>
        <DrawerHeader>
          <Link to="/">
            <AssetLogo />
          </Link>
        </DrawerHeader>
        <DrawerContent>
          <Link to="/">
            <DrawerPanel label="Exhibitor Portal" description="Track leads for your event exhibits" active />
          </Link>
          {!!props.menu && (
            <>
              <DrawerSpacer />
              {props.menu}
            </>
          )}
        </DrawerContent>
        <DrawerFooter>
          <Flex horizontal gap={0.5}>
            <Icon size={1.5} icon={UserCircleIcon} />
            <Flex gap={1}>
              {authenticationStore.profile?.givenName && (
                <Text scale={-1}>{capitalize(authenticationStore.profile.givenName)}</Text>
              )}
              <Text muted scale={-1}>
                Profile settings
              </Text>
              <Text muted scale={-1} onClick={() => session.logoutRedirect()}>
                Log out
              </Text>
            </Flex>
          </Flex>
        </DrawerFooter>
      </Drawer>
      <Flex flex={1} align="stretch" style={{ height: '100%' }}>
        {props.title && (
          <AppBar>
            {/* if the title is a string render a title component */}
            {typeof props.title === 'string' ? (
              <Title size="h3" superBold>
                {props.title}
              </Title>
            ) : (
              <>
                {/* otherwise render whatever component was provided. */}
                {props.title}
              </>
            )}
          </AppBar>
        )}
        <Box padding={1} style={{ overflow: 'auto' }}>
          {props.children ?? <Outlet />}
        </Box>
      </Flex>
    </Flex>
  );
}
