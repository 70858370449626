import type { IContainer } from './IContainer';
import type { IDisposable } from './IDisposable';
import { InjectionToken, InjectionTokenLike } from './InjectionToken';

export const IInjector = new InjectionToken<IInjector>('IInjector');

export interface IInjector extends IDisposable {
  resolve<T>(injectionToken: InjectionTokenLike<T>): T;

  has<T>(injectionToken: InjectionTokenLike<T>): boolean;

  createChildContainer(): IContainer;
}
