import { Provider } from './Provider';

export class Module {
  readonly imports: Module[];
  readonly providers: Provider[];

  constructor(metadata: ModuleConfiguration) {
    this.imports = Object.freeze(metadata.imports ?? []) as Module[];
    this.providers = Object.freeze(metadata.providers ?? []) as Provider[];
  }
}

export interface ModuleConfiguration {
  imports?: Module[];
  providers?: Provider[];
}
