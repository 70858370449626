import { IInjector } from './IInjector';
import { ClassType, InjectionToken, InjectionTokenLike } from './InjectionToken';

export type Provider<T = unknown> = ClassProvider<T> | ValueProvider<T> | FactoryProvider<T> | AliasProvider<T>;

export interface ClassProvider<T> {
  provide: InjectionTokenLike<T>;
  useClass: ClassType<T>;
  lifecycle?: Lifecycle;
}

export interface ValueProvider<T> {
  provide: InjectionTokenLike<T>;
  useValue: T;
}

export interface AliasProvider<T> {
  provide: InjectionTokenLike<T>;
  alias: InjectionTokenLike<T>;
}

export interface FactoryProvider<T> {
  provide: InjectionTokenLike<T>;
  useFactory: Factory<T>;
  lifecycle?: Lifecycle;
}

export type Factory<T> = (injector: IInjector) => T;

export enum Lifecycle {
  /**
   * The default registration scope, a new instance will be created with each resolve
   */
  Transient = 0,
  /**
   * Each resolve will return the same instance (including resolves from child containers)
   */
  Singleton = 1,
  /**
   * The same instance will be resolved for each resolution of this dependency during a single resolution chain
   */
  ResolutionScoped = 2,
  /**
   * The dependency container will return the same instance each time a resolution for this dependency is requested. This is similar to being a singleton, however if a child container is made, that child container will resolve an instance unique to it.
   */
  ContainerScoped = 3,
}

export function provide<T>(
  token: InjectionTokenLike<T>,
  implementation: ClassType<T>,
  lifecycle?: Lifecycle
): ClassProvider<T> {
  return { provide: token, useClass: implementation, lifecycle };
}

export function provideValue<T>(token: InjectionTokenLike<T>, value: T): ValueProvider<T> {
  return { provide: token, useValue: value };
}

export function provideFactory<T>(
  token: InjectionTokenLike<T>,
  factory: Factory<T>,
  lifecycle?: Lifecycle
): FactoryProvider<T> {
  return { provide: token, useFactory: factory, lifecycle };
}
