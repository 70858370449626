import { ServiceType } from '@bufbuild/protobuf';
import { PromiseClient } from '@connectrpc/connect';
import { Provider } from 'libs/uikit/ioc';
import { AuthenticationInterceptor, ITokenAccessor } from './AuthenticationInterceptor';
import { DevtoolsInterceptor } from './DevtoolsInterceptor';
import { ErrorInterceptor } from './ErrorInterceptor';
import { GrpcWebChannel } from './GrpcWebChannel';
import { ClientClass } from './GrpcWebClientFactory';
import { Interceptor, withInterceptors } from './Interceptor';
import { Rpc } from './Rpc';

export function registerGrpcClient<T extends ServiceType>(
  client: ClientClass<T>,
  endpoint: string
): Provider<PromiseClient<T>> {
  return {
    provide: client,
    useFactory: (injector) => {
      // Request interceptors (middleware).
      // The order of the interceptors is important.
      // Interceptors are run in sequence.
      const interceptors = new Array<Interceptor>();

      // Add the authentication middleware if there's a token accessor available.
      if (injector.has(ITokenAccessor)) {
        interceptors.push(new AuthenticationInterceptor(injector.resolve(ITokenAccessor)));
      }

      // Add the error interceptor which enhances browser errors with improved messages
      interceptors.push(new ErrorInterceptor());

      // Add the devtools interceptor for debugging.
      interceptors.push(new DevtoolsInterceptor());

      return new client(withInterceptors(new GrpcWebChannel(endpoint), ...interceptors));
    },
  };
}
