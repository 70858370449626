import { Timestamp } from '@bufbuild/protobuf';
import { Temporal, toInstant } from 'libs/uikit/lib';

/**
 * ToZonedDateTime is a helper function that converts a GRPC Timestamp
 * value into a Temporal.ZonedDateTime value using the given timezone.
 *
 * Note: both the Timestamp and ZonedDateTime represent an absolute
 * point in time; the ZonedDateTime is localized.
 */
export function toZonedDateTime(
  timestamp: Timestamp | undefined,
  timeZoneId: string
): Temporal.ZonedDateTime | undefined {
  if (!timestamp) {
    return undefined;
  }

  return toInstant(timestamp.toDate()).toZonedDateTimeISO(timeZoneId);
}
