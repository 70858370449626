import { SigninRedirectArgs, User, UserManager } from 'oidc-client-ts';
import { IOpenIDConnectClientConfig } from './IOpenIDConnectConfig';
import { IOpenIDConnectHandler, OpenIDConnectUser, RedirectState } from './IOpenIDConnectHandler';
import { TypedEvent } from './TypedEventEmitter';

export class OpenIDConnectHandler implements IOpenIDConnectHandler {
  private userManager: UserManager;

  constructor(config: IOpenIDConnectClientConfig) {
    this.userManager = new UserManager({
      authority: config.authority,
      client_id: config.clientId,
      redirect_uri: config.redirectUri,
      post_logout_redirect_uri: config.postLogoutRedirectUri,
      automaticSilentRenew: true,
      monitorSession: false,
      scope: 'openid profile offline_access',
      revokeTokensOnSignout: true,
    });

    this.userManager.events.addUserLoaded((user) => {
      console.info('UserManager: user loaded');
      this.currentUserChanged.emit(convertUser(user));
    });

    this.userManager.events.addUserSignedOut(() => {
      console.info('UserManager: user session signed out');
      this.currentUserChanged.emit(undefined);
    });

    this.userManager.events.addUserUnloaded(() => {
      console.info('UserManager: user unloaded');
      this.currentUserChanged.emit(undefined);
    });

    this.userManager.events.addAccessTokenExpiring(() => {
      console.info('UserManager: token expiring soon');
    });

    this.userManager.events.addAccessTokenExpired(() => {
      console.info('UserManager: token has expired');
      this.currentUserChanged.emit(undefined);
    });

    this.userManager.events.addUserSessionChanged(() => {
      console.info('UserManager: user session changed');
    });

    this.userManager.events.addUserSignedIn(() => {
      console.info('UserManager: user signed in');
    });

    this.userManager.events.addSilentRenewError((error) => {
      console.info('UserManager: silent renew error', error.message);
    });
  }

  currentUserChanged = new TypedEvent<OpenIDConnectUser | undefined>();

  async currentUser(): Promise<OpenIDConnectUser | undefined> {
    const user = await this.userManager.getUser();

    if (!user || user.expired) {
      return undefined;
    }

    return convertUser(user);
  }

  async signinRedirect(state?: RedirectState): Promise<void> {
    const args: SigninRedirectArgs = {};
    if (state) {
      args.state = JSON.stringify(state);
    }
    await this.userManager.signinRedirect(args);
  }

  async signinCallback(): Promise<RedirectState> {
    const user = await this.userManager.signinCallback();
    if (user && typeof user.state === 'string') {
      try {
        return JSON.parse(user.state) || {};
      } catch {
        // log the error but don't throw because the state
        // is non-critical to the overall login process.
        console.error('OpenIDConnectHandler: unable to parse redirect state');
      }
    }

    return {};
  }

  async signoutRedirect(): Promise<void> {
    await this.userManager.signoutRedirect();
  }

  async signoutCallback(): Promise<void> {
    await this.userManager.signoutCallback();
  }
}

function convertUser(user: User): OpenIDConnectUser {
  return {
    subject: user.profile.sub,
    accessToken: user.access_token,
    givenName: user.profile.given_name,
    familyName: user.profile.family_name,
  };
}
