import { InjectionToken, InjectionTokenLike } from '../Abstractions/InjectionToken';
import { Lifecycle } from '../Abstractions/Provider';
import * as tsyringe from './Tsyringe';

export function getTsyringeToken<T>(injectionToken: InjectionTokenLike<T>): tsyringe.InjectionToken<T> {
  if (injectionToken instanceof InjectionToken) {
    return injectionToken.token;
  }
  return injectionToken;
}

export function getTsyringeLifecycle(lifecycle: Lifecycle = Lifecycle.Transient): tsyringe.Lifecycle {
  switch (lifecycle) {
    case Lifecycle.Transient:
      return tsyringe.Lifecycle.Transient;
    case Lifecycle.Singleton:
      return tsyringe.Lifecycle.Singleton;
    case Lifecycle.ResolutionScoped:
      return tsyringe.Lifecycle.ResolutionScoped;
    case Lifecycle.ContainerScoped:
      return tsyringe.Lifecycle.ContainerScoped;
  }
}
