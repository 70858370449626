import { AnyMessage } from '@bufbuild/protobuf';
import { ConnectError, Transport } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { GrpcWebError } from './GrpcWebError';
import { Metadata, MethodDescriptor, Rpc, UnknownMessage } from './Rpc';

/**
 * GrpcWebChannel implements a grpc web client object compatible with generated
 * service code.
 */
export class GrpcWebChannel implements Rpc {
  private readonly channel: Transport;

  constructor(host: string) {
    this.channel = createGrpcWebTransport({
      baseUrl: host,
    });
  }

  async unary<TRequest extends UnknownMessage, TResponse extends UnknownMessage>(
    descriptor: MethodDescriptor,
    request: TRequest,
    metadata: Metadata,
    signal: AbortSignal
  ): Promise<TResponse> {
    const { service, method } = descriptor as any;

    try {
      const response = await this.channel.unary(
        service,
        method,
        signal,
        undefined,
        metadata,
        request as AnyMessage,
        undefined
      );
      return response.message as TResponse;
    } catch (error) {
      // mapping the grpc library's error object to our standard error object.
      if (error instanceof ConnectError) {
        throw new GrpcWebError(error.rawMessage, error.code, error.metadata);
      }
      throw error;
    }
  }
}
