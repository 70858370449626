// @generated by protoc-gen-es v1.7.2 with parameter "keep_empty_files=true,target=js+dts"
// @generated from file services/web/protos/ExhibitorPortal/ExhibitorEvents.proto (package ocs.exhibitors.protos, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message ocs.exhibitors.protos.ListMarketplacesRequest
 */
export const ListMarketplacesRequest = proto3.makeMessageType(
  "ocs.exhibitors.protos.ListMarketplacesRequest",
  () => [
    { no: 1, name: "exhibitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ocs.exhibitors.protos.ListMarketplacesResponse
 */
export const ListMarketplacesResponse = proto3.makeMessageType(
  "ocs.exhibitors.protos.ListMarketplacesResponse",
  () => [
    { no: 1, name: "items", kind: "message", T: MarketplaceListItem, repeated: true },
  ],
);

/**
 * @generated from message ocs.exhibitors.protos.MarketplaceListItem
 */
export const MarketplaceListItem = proto3.makeMessageType(
  "ocs.exhibitors.protos.MarketplaceListItem",
  () => [
    { no: 1, name: "marketplace_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "starts_at", kind: "message", T: Timestamp },
    { no: 6, name: "ends_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message ocs.exhibitors.protos.GetMarketplaceStatsRequest
 */
export const GetMarketplaceStatsRequest = proto3.makeMessageType(
  "ocs.exhibitors.protos.GetMarketplaceStatsRequest",
  () => [
    { no: 1, name: "exhibitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "marketplace_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ocs.exhibitors.protos.GetMarketplaceStatsResponse
 */
export const GetMarketplaceStatsResponse = proto3.makeMessageType(
  "ocs.exhibitors.protos.GetMarketplaceStatsResponse",
  () => [
    { no: 1, name: "credential_stats", kind: "message", T: CredentialStats },
    { no: 2, name: "ticket_stats", kind: "message", T: TicketStats },
    { no: 3, name: "ticket_option_stats", kind: "message", T: TicketOptionStats },
    { no: 4, name: "lead_stats", kind: "message", T: LeadStats },
  ],
);

/**
 * @generated from message ocs.exhibitors.protos.CredentialStats
 */
export const CredentialStats = proto3.makeMessageType(
  "ocs.exhibitors.protos.CredentialStats",
  () => [
    { no: 1, name: "credentials_allocated", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "credentials_consumed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message ocs.exhibitors.protos.TicketStats
 */
export const TicketStats = proto3.makeMessageType(
  "ocs.exhibitors.protos.TicketStats",
  () => [
    { no: 1, name: "tickets_ordered", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message ocs.exhibitors.protos.TicketOptionStats
 */
export const TicketOptionStats = proto3.makeMessageType(
  "ocs.exhibitors.protos.TicketOptionStats",
  () => [
    { no: 1, name: "options_redeemed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message ocs.exhibitors.protos.LeadStats
 */
export const LeadStats = proto3.makeMessageType(
  "ocs.exhibitors.protos.LeadStats",
  () => [
    { no: 1, name: "leads_captured", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message ocs.exhibitors.protos.GetMarketplaceDetailsRequest
 */
export const GetMarketplaceDetailsRequest = proto3.makeMessageType(
  "ocs.exhibitors.protos.GetMarketplaceDetailsRequest",
  () => [
    { no: 1, name: "exhibitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "marketplace_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ocs.exhibitors.protos.GetMarketplaceDetailsResponse
 */
export const GetMarketplaceDetailsResponse = proto3.makeMessageType(
  "ocs.exhibitors.protos.GetMarketplaceDetailsResponse",
  () => [
    { no: 1, name: "details", kind: "message", T: MarketplaceDetails },
  ],
);

/**
 * @generated from message ocs.exhibitors.protos.MarketplaceDetails
 */
export const MarketplaceDetails = proto3.makeMessageType(
  "ocs.exhibitors.protos.MarketplaceDetails",
  () => [
    { no: 1, name: "marketplace_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "starts_at", kind: "message", T: Timestamp },
    { no: 6, name: "ends_at", kind: "message", T: Timestamp },
    { no: 7, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "location", kind: "message", T: MarketplaceLocation },
    { no: 9, name: "organizer_contact_information", kind: "message", T: MarktplaceOrganizerContact },
  ],
);

/**
 * @generated from message ocs.exhibitors.protos.MarketplaceLocation
 */
export const MarketplaceLocation = proto3.makeMessageType(
  "ocs.exhibitors.protos.MarketplaceLocation",
  () => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "venue_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ocs.exhibitors.protos.MarktplaceOrganizerContact
 */
export const MarktplaceOrganizerContact = proto3.makeMessageType(
  "ocs.exhibitors.protos.MarktplaceOrganizerContact",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

