import { Metadata, MethodDescriptor, Rpc, UnaryCall } from './Rpc';

/**
 * Interceptor is an interface that can be used to implement a GRPC interceptor.
 */
export interface Interceptor {
  /**
   * Unary request method interceptor
   * @param descriptor The GRPC method descriptor. Implementation depends on codegen, ignore this parameter.
   * @param request The GRPC request message.
   * @param metadata GRPC metadata headers. The metadata collection may be mutated.
   * @param signal An AbortSignal that can be used to cancel the request, set timeouts, etc.
   * @param next The unary call continuation. Interceptors should call and return it's result to complete the unary call.
   */
  unary<TRequest, TResponse>(
    descriptor: MethodDescriptor,
    request: TRequest,
    metadata: Metadata,
    signal: AbortSignal,
    next: UnaryCall<TRequest, TResponse>
  ): Promise<TResponse>;
}

/**
 * WithInterceptors wraps a channel with a set of interceptors that will be executed
 * in the given order.
 *
 * Interceptors are sometimes referred to as middleware and can be used to modify the
 * behaviour of an API call.
 *
 * @param channel a GRPC channel object
 * @param interceptors a set of Interceptor objects that implement the Interceptor interface
 * @returns the wrapped channel
 */
export function withInterceptors(channel: Rpc, ...interceptors: Interceptor[]): Rpc {
  // note that we must use "reduceRight" so that we layer the interceptors in the expected order.
  // i.e. the first interceptor in the list should be called first when a request is made;
  // meaning the first interceptor must be the outter-most layer in our stack of InterceptorAdaptors.
  return interceptors.reduceRight((current: Rpc, interceptor) => {
    return new InterceptorAdaptor(current, interceptor);
  }, channel);
}

class InterceptorAdaptor implements Rpc {
  constructor(
    private inner: Rpc,
    private interceptor: Interceptor
  ) {}

  async unary<TRequest, TResponse>(
    descriptor: MethodDescriptor,
    request: TRequest,
    metadata: Metadata,
    signal: AbortSignal
  ): Promise<TResponse> {
    return await this.interceptor.unary<TRequest, TResponse>(
      descriptor,
      request,
      metadata,
      signal,
      this.inner.unary.bind(this.inner)
    );
  }
}
