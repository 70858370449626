import { InjectionToken } from 'libs/uikit/ioc';
import { ITypedEvent } from './TypedEventEmitter';

export interface IOpenIDConnectHandler {
  currentUser(): Promise<OpenIDConnectUser | undefined>;
  currentUserChanged: ITypedEvent<OpenIDConnectUser | undefined>;
  signinRedirect(state?: RedirectState): Promise<void>;
  signinCallback(): Promise<RedirectState>;
  signoutRedirect(): Promise<void>;
  signoutCallback(): Promise<void>;
}

export const IOpenIDConnectHandler = new InjectionToken<IOpenIDConnectHandler>('IOpenIDConnectHandler');

export type OpenIDConnectUser = {
  subject: string;
  accessToken: string;
  familyName?: string;
  givenName?: string;
};

export type RedirectState = {
  returnUrl?: string;
};
