export function macroTokenToFormatOpts(macro: string): Intl.DateTimeFormatOptions | undefined {
  if (macro in macros) {
    return macros[macro];
  }
  return undefined;
}

const n = 'numeric' as const;
const s = 'short' as const;
const l = 'long' as const;

const DATE_SHORT = {
  year: n,
  month: n,
  day: n,
} as const;

const DATE_MED = {
  year: n,
  month: s,
  day: n,
} as const;

// const DATE_MED_WITH_WEEKDAY = {
//   year: n,
//   month: s,
//   day: n,
//   weekday: s,
// } as const;

const DATE_FULL = {
  year: n,
  month: l,
  day: n,
} as const;

const DATE_HUGE = {
  year: n,
  month: l,
  day: n,
  weekday: l,
} as const;

const TIME_SIMPLE = {
  hour: n,
  minute: n,
} as const;

const TIME_WITH_SECONDS = {
  hour: n,
  minute: n,
  second: n,
} as const;

const TIME_WITH_SHORT_OFFSET = {
  hour: n,
  minute: n,
  second: n,
  timeZoneName: s,
} as const;

const TIME_WITH_LONG_OFFSET = {
  hour: n,
  minute: n,
  second: n,
  timeZoneName: l,
} as const;

const TIME_24_SIMPLE = {
  hour: n,
  minute: n,
  hourCycle: 'h23',
} as const;

const TIME_24_WITH_SECONDS = {
  hour: n,
  minute: n,
  second: n,
  hourCycle: 'h23',
} as const;

const TIME_24_WITH_SHORT_OFFSET = {
  hour: n,
  minute: n,
  second: n,
  hourCycle: 'h23',
  timeZoneName: s,
} as const;

const TIME_24_WITH_LONG_OFFSET = {
  hour: n,
  minute: n,
  second: n,
  hourCycle: 'h23',
  timeZoneName: l,
} as const;

const DATETIME_SHORT = {
  year: n,
  month: n,
  day: n,
  hour: n,
  minute: n,
} as const;

const DATETIME_SHORT_WITH_SECONDS = {
  year: n,
  month: n,
  day: n,
  hour: n,
  minute: n,
  second: n,
} as const;

const DATETIME_MED = {
  year: n,
  month: s,
  day: n,
  hour: n,
  minute: n,
} as const;

const DATETIME_MED_WITH_SECONDS = {
  year: n,
  month: s,
  day: n,
  hour: n,
  minute: n,
  second: n,
} as const;

// const DATETIME_MED_WITH_WEEKDAY = {
//   year: n,
//   month: s,
//   day: n,
//   weekday: s,
//   hour: n,
//   minute: n,
// } as const;

const DATETIME_FULL = {
  year: n,
  month: l,
  day: n,
  hour: n,
  minute: n,
  timeZoneName: s,
} as const;

const DATETIME_FULL_WITH_SECONDS = {
  year: n,
  month: l,
  day: n,
  hour: n,
  minute: n,
  second: n,
  timeZoneName: s,
} as const;

const DATETIME_HUGE = {
  year: n,
  month: l,
  day: n,
  weekday: l,
  hour: n,
  minute: n,
  timeZoneName: l,
} as const;

const DATETIME_HUGE_WITH_SECONDS = {
  year: n,
  month: l,
  day: n,
  weekday: l,
  hour: n,
  minute: n,
  second: n,
  timeZoneName: l,
} as const;

const macros: Record<string, Intl.DateTimeFormatOptions> = {
  D: DATE_SHORT,
  DD: DATE_MED,
  DDD: DATE_FULL,
  DDDD: DATE_HUGE,
  t: TIME_SIMPLE,
  tt: TIME_WITH_SECONDS,
  ttt: TIME_WITH_SHORT_OFFSET,
  tttt: TIME_WITH_LONG_OFFSET,
  T: TIME_24_SIMPLE,
  TT: TIME_24_WITH_SECONDS,
  TTT: TIME_24_WITH_SHORT_OFFSET,
  TTTT: TIME_24_WITH_LONG_OFFSET,
  f: DATETIME_SHORT,
  ff: DATETIME_MED,
  fff: DATETIME_FULL,
  ffff: DATETIME_HUGE,
  F: DATETIME_SHORT_WITH_SECONDS,
  FF: DATETIME_MED_WITH_SECONDS,
  FFF: DATETIME_FULL_WITH_SECONDS,
  FFFF: DATETIME_HUGE_WITH_SECONDS,
};
