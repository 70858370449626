import { CalendarIcon } from '@heroicons/react/24/outline';
import { Temporal } from '@js-temporal/polyfill';
import { Flex, Icon, Text, Title, TitleProps, Tooltip, browserTimeZone } from 'libs/uikit/lib';
import { formatTemporal } from 'libs/uikit/lib/Time/Formatter';
import React from 'react';

interface EventTitleBarProps {
  name: string;
  startsAt: Temporal.ZonedDateTime;
  endsAt: Temporal.ZonedDateTime;
  image: string;
  variant?: 'large' | 'list-item';
}

export function EventTitleBar(props: EventTitleBarProps) {
  const variant = props.variant;

  return (
    <Flex horizontal gap={gapSize(variant)} align="center">
      <img width={imageSize(variant)} height={imageSize(variant)} src={props.image} />
      <Flex gap={0.25}>
        <Tooltip
          hover
          tip={
            <Text uppercase scale={textScale(variant)}>
              {formatTemporal(props.startsAt.withTimeZone(browserTimeZone()), 'd MMM')} -{' '}
              {formatTemporal(props.endsAt.withTimeZone(browserTimeZone()), 'd MMM (ZZZZ)')}
            </Text>
          }
        >
          <Flex horizontal gap={0.5} align="center">
            <Icon icon={CalendarIcon} muted />
            <Text uppercase muted scale={textScale(variant)}>
              {formatTemporal(props.startsAt, 'd MMM')} - {formatTemporal(props.endsAt, 'd MMM (ZZZZ)')}
            </Text>
          </Flex>
        </Tooltip>
        <Title size={titleSize(variant)} bold>
          {props.name}
        </Title>
      </Flex>
    </Flex>
  );
}

function gapSize(variant: EventTitleBarProps['variant']) {
  if (variant === 'large') {
    return 1.5;
  }

  return 0.5;
}

function titleSize(variant: EventTitleBarProps['variant']): TitleProps['size'] {
  if (variant === 'large') {
    return 'h1';
  }

  if (variant === 'list-item') {
    return 'h4';
  }

  return 'h5';
}

function textScale(variant: EventTitleBarProps['variant']) {
  if (variant === 'large') {
    return -1;
  }

  return -2;
}

function imageSize(variant: EventTitleBarProps['variant']) {
  if (variant === 'large') {
    return 96;
  }

  if (variant === 'list-item') {
    return 128;
  }

  return 64;
}
