// @generated by protoc-gen-connect-es v1.4.0 with parameter "keep_empty_files=true,target=js+dts"
// @generated from file services/web/protos/ExhibitorPortal/Exhibitors.proto (package ocs.exhibitors.protos, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ListMyExhibitorsRequest, ListMyExhibitorsResponse } from "./Exhibitors_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ocs.exhibitors.protos.Exhibitors
 */
export const Exhibitors = {
  typeName: "ocs.exhibitors.protos.Exhibitors",
  methods: {
    /**
     * @generated from rpc ocs.exhibitors.protos.Exhibitors.ListMyExhibitors
     */
    listMyExhibitors: {
      name: "ListMyExhibitors",
      I: ListMyExhibitorsRequest,
      O: ListMyExhibitorsResponse,
      kind: MethodKind.Unary,
    },
  }
};

