import { ClientClass, clientFactory, registerGrpcClient } from 'libs/grpckit/src';
import { ITokenAccessor } from 'libs/grpckit/src/AuthenticationInterceptor';
import { Module, provideFactory } from 'libs/uikit/ioc';
import { ExhibitorEvents } from 'services/web/protos/ExhibitorPortal/ExhibitorEvents_connect';
import { Exhibitors } from 'services/web/protos/ExhibitorPortal/Exhibitors_connect';
import { IAuthenticationStore } from '../Authentication/AuthenticationStore';

export const ExhibitorsClient: ClientClass<typeof Exhibitors> = clientFactory(Exhibitors);
export const ExhibitorEventsClient: ClientClass<typeof ExhibitorEvents> = clientFactory(ExhibitorEvents);

export const ApiModule = new Module({
  providers: [
    provideFactory(ITokenAccessor, (injector) => injector.resolve(IAuthenticationStore)),

    // TODO config
    registerGrpcClient(ExhibitorsClient, 'https://www.testing.cetdemo.com'),
    registerGrpcClient(ExhibitorEventsClient, 'https://www.testing.cetdemo.com'),
  ],
});
