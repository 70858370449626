// @generated by protoc-gen-connect-es v1.4.0 with parameter "keep_empty_files=true,target=js+dts"
// @generated from file services/web/protos/ExhibitorPortal/ExhibitorEvents.proto (package ocs.exhibitors.protos, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetMarketplaceDetailsRequest, GetMarketplaceDetailsResponse, GetMarketplaceStatsRequest, GetMarketplaceStatsResponse, ListMarketplacesRequest, ListMarketplacesResponse } from "./ExhibitorEvents_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ocs.exhibitors.protos.ExhibitorEvents
 */
export const ExhibitorEvents = {
  typeName: "ocs.exhibitors.protos.ExhibitorEvents",
  methods: {
    /**
     * @generated from rpc ocs.exhibitors.protos.ExhibitorEvents.ListMarketplaces
     */
    listMarketplaces: {
      name: "ListMarketplaces",
      I: ListMarketplacesRequest,
      O: ListMarketplacesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc ocs.exhibitors.protos.ExhibitorEvents.GetMarketplaceStats
     */
    getMarketplaceStats: {
      name: "GetMarketplaceStats",
      I: GetMarketplaceStatsRequest,
      O: GetMarketplaceStatsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc ocs.exhibitors.protos.ExhibitorEvents.GetMarketplaceDetails
     */
    getMarketplaceDetails: {
      name: "GetMarketplaceDetails",
      I: GetMarketplaceDetailsRequest,
      O: GetMarketplaceDetailsResponse,
      kind: MethodKind.Unary,
    },
  }
};

