import { Lifecycle, Module, provideFactory } from 'libs/uikit/ioc';
import { DevelopmentOpenIDConnectHandler } from './DevelopmentOpenIDConnectHandler';
import { IOpenIDConnectConfig } from './IOpenIDConnectConfig';
import { IOpenIDConnectHandler } from './IOpenIDConnectHandler';
import { OpenIDConnectHandler } from './OpenIDConnectHandler';

export const OpenIDConnectModule = new Module({
  providers: [
    provideFactory(
      IOpenIDConnectHandler,
      (injector) => {
        const config = injector.resolve(IOpenIDConnectConfig);

        if (config.type === 'development') {
          return new DevelopmentOpenIDConnectHandler(config);
        }

        return new OpenIDConnectHandler(config);
      },
      Lifecycle.Singleton
    ),
  ],
});
