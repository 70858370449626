import { useInjection } from 'libs/uikit/ioc/React';
import { Button } from 'libs/uikit/lib';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { IAuthenticationStore } from './AuthenticationStore';
import { ISignInManager } from './SignInManager';

export const AuthenticationGuard = observer(() => {
  const store = useInjection(IAuthenticationStore);
  const session = useInjection(ISignInManager);
  const location = useLocation();

  if (!store.ready) {
    return null;
  }

  if (!store.isAuthenticated) {
    return <Button onClick={() => session.loginRedirect({ returnUrl: location.pathname })}>login</Button>;
  }

  return <Outlet />;
});
