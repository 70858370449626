import { InjectionToken } from 'libs/uikit/ioc';

export type IOpenIDConnectConfig = IOpenIDConnectClientConfig | IOpenIDConnectDevelopment;

export interface IOpenIDConnectClientConfig {
  type: 'client';
  authority: string;
  clientId: string;
  redirectUri: string;
  postLogoutRedirectUri: string;
}

export interface IOpenIDConnectDevelopment {
  type: 'development';
  redirectUri: string;
  postLogoutRedirectUri: string;
}

export const IOpenIDConnectConfig = new InjectionToken<IOpenIDConnectConfig>('IOpenIDConnectConfig');
