import { Box, Chip, Flex, Intent, Paper } from 'libs/uikit/lib';
import React from 'react';

interface DashboardCardProps {
  chip?: string;
  intent?: Intent;
  children?: React.ReactNode;
  align?: 'center';
}

export function DashboardCard(props: DashboardCardProps) {
  return (
    <Paper elevation={1} style={{ padding: 1.5 * 16 }}>
      {props.chip && <DashboardCardChip label="not available" />}
      <Flex gap={1} align={props.align}>
        {props.children}
      </Flex>
    </Paper>
  );
}

interface DashboardCardChipProps {
  label: string;
}

function DashboardCardChip(props: DashboardCardChipProps) {
  return (
    <Box style={{ position: 'absolute', top: 0, left: '50%', transform: 'translate(-50%, -50%)' }}>
      <Chip label={props.label.toUpperCase()} color="dark-blue" scale={-3} />
    </Box>
  );
}
