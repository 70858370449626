import { InjectionToken, inject, injectable } from 'libs/uikit/ioc';
import { IOpenIDConnectHandler } from './OpenIDConnect/IOpenIDConnectHandler';

/**
 * ISignInManager provides methods manage a user's login session.
 */
export interface ISignInManager {
  /**
   * LoginRedirect redirects the user to the signin page.
   */
  loginRedirect(options?: LoginRedirectOptions): Promise<void>;

  /**
   * LogoutRedirect redirects the user to the signout page.
   */
  logoutRedirect(): Promise<void>;
}

export const ISignInManager = new InjectionToken<ISignInManager>('ISigninManager');

export type LoginRedirectOptions = {
  returnUrl?: string;
};

@injectable()
export class SignInManager implements ISignInManager {
  constructor(@inject(IOpenIDConnectHandler) private oidc: IOpenIDConnectHandler) {}

  async loginRedirect(options?: LoginRedirectOptions): Promise<void> {
    await this.oidc.signinRedirect(options);
  }

  async logoutRedirect(): Promise<void> {
    await this.oidc.signoutRedirect();
  }
}
