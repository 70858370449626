import { useContext, useMemo } from 'react';
import { InjectionTokenLike } from '../Abstractions/InjectionToken';
import { __context } from './InjectorContext';

export function useInjection<T>(injectionToken: InjectionTokenLike<T>): T {
  const instance = useOptionalInjection(injectionToken);
  if (!instance) {
    throw new Error(`missing required dependency: ${String(injectionToken)}`);
  }
  return instance;
}

export function useOptionalInjection<T>(injectionToken: InjectionTokenLike<T>): T | undefined {
  const { injector } = useContext(__context);

  return useMemo(() => {
    if (!injector) {
      return undefined;
    }
    return injector.resolve(injectionToken);
  }, [injector, injectionToken]);
}
