// This code is used to connect the grpc client
// with the grpc browser devtools.
// https://github.com/stephenh/ts-proto/issues/504

import { AnyMessage, Message, PartialMessage, toPlainMessage } from '@bufbuild/protobuf';
import { Interceptor } from './Interceptor';
import { Metadata, UnaryCall } from './Rpc';

/**
 * DevtoolsInterceptor is an interceptor that logs all GRPC requests
 * to the SafetyCulture/grpc-web-devtools browser extension for debugging.
 *
 * You can find the browser extension here:
 * https://github.com/SafetyCulture/grpc-web-devtools
 */
export class DevtoolsInterceptor implements Interceptor {
  async unary<TRequest, TResponse>(
    descriptor: any,
    request: TRequest,
    metadata: Metadata,
    signal: AbortSignal,
    next: UnaryCall<TRequest, TResponse>
  ): Promise<TResponse> {
    const jsonRequest = toPlainMessage(request as PartialMessage<AnyMessage>);
    let jsonResponse: any;
    let apiError: any;

    try {
      const realResponse = await next(descriptor, request, metadata, signal);

      // convert from a GRPC message to JSON object
      jsonResponse = toPlainMessage(realResponse as Message<AnyMessage>);

      return realResponse;
    } catch (error: any) {
      apiError = { code: error.code, message: error.message || error.metadata?.['grpc-message'] || '' };
      throw error;
    } finally {
      window.postMessage(
        JSON.parse(
          JSON.stringify(
            {
              type: '__GRPCWEB_DEVTOOLS__',
              method: `${descriptor.service.typeName}/${descriptor.method.localName}`,
              methodType: 'unary',
              request: jsonRequest,
              response: jsonResponse,
              error: apiError,
            },
            jsonStringifyReplacer
          )
        ),
        window.location.origin
      );
    }
  }
}

/**
 * Protobuf messages can contain "bigint" primatives which cannot be serialized
 * to JSON without special handling.
 * Below is a "replacer" function that can be used with JSON.stringify() that will
 * serialize bigint values as needed.
 * @param key
 * @param value
 * @returns
 */
function jsonStringifyReplacer(_key: string, value: unknown) {
  return typeof value === 'bigint' ? value.toString() : value; // return everything else unchanged
}
