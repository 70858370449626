import { InjectionToken } from 'libs/uikit/ioc';
import { Interceptor } from './Interceptor';
import { Metadata, UnaryCall } from './Rpc';

export interface ITokenAccessor {
  readonly currentToken?: string;
}

export const ITokenAccessor = new InjectionToken<ITokenAccessor>('ITokenAccessor');

export class AuthenticationInterceptor implements Interceptor {
  constructor(private tokenAccessor: ITokenAccessor) {}

  unary<TRequest, TResponse>(
    descriptor: unknown,
    request: TRequest,
    metadata: Metadata,
    signal: AbortSignal,
    next: UnaryCall<TRequest, TResponse>
  ): Promise<TResponse> {
    if (this.tokenAccessor.currentToken) {
      metadata.set('Authorization', `Bearer ${this.tokenAccessor.currentToken}`);
    }

    return next(descriptor, request, metadata, signal);
  }
}
