import { IContainer } from '../Abstractions/IContainer';
import { IInjector } from '../Abstractions/IInjector';
import { FactoryProvider, Lifecycle, Provider } from '../Abstractions/Provider';
import { InjectorAdapter } from './InjectorAdapter';
import { getTsyringeLifecycle, getTsyringeToken } from './Mappers';
import * as tsyringe from './Tsyringe';

export class ContainerAdapter implements IContainer {
  private constructor(private container: tsyringe.DependencyContainer) {}

  static root() {
    return new ContainerAdapter(tsyringe.container.createChildContainer());
  }

  static childOf(container: tsyringe.DependencyContainer) {
    return new ContainerAdapter(container);
  }

  register<T>(provider: Provider<T>): void {
    const token = getTsyringeToken(provider.provide);

    if ('useFactory' in provider) {
      this.registerFactory(token, provider);
      return;
    }

    if ('useValue' in provider) {
      this.container.register<T>(token, { useValue: provider.useValue });
      return;
    }

    if ('useClass' in provider) {
      this.container.register<T>(
        token,
        { useClass: provider.useClass },
        {
          lifecycle: getTsyringeLifecycle(provider.lifecycle),
        }
      );
      return;
    }

    if ('alias' in provider) {
      this.container.register<T>(token, { useToken: getTsyringeToken(provider.alias) });
      return;
    }

    throw new Error('unknown provider configuration');
  }

  build(): IInjector {
    // create the injector adaptor
    const injector = new InjectorAdapter(this.container);

    // register the injector within it's own dependency container
    // so that other dependencies can resolve it if they need to.
    this.container.register<IInjector>(getTsyringeToken(IInjector), { useValue: injector });

    // return the build injector
    return injector;
  }

  private registerFactory<T>(token: tsyringe.InjectionToken<T>, provider: FactoryProvider<T>) {
    // The tsyringeFactory method wraps the given `provider.useFactory` function
    // because we need to resolve the underlying tsyringe dependency container
    // and then resolve our IInjector adaptor.
    // We do this because the IInjector is passed to the `useFactory` function allowing
    // it to resolve any dependencies it needs.
    let tsyringeFactory = (container: tsyringe.DependencyContainer) => {
      return provider.useFactory(container.resolve(getTsyringeToken(IInjector)));
    };

    // If the provider declares that it's a singleton or container scoped singleton
    // then we need to wrap the factory function with tsyringe's instance caching factory.
    // The instance caching factory simply caches the whatever the actual factory function returns.
    if (provider.lifecycle === Lifecycle.Singleton) {
      tsyringeFactory = tsyringe.instanceCachingFactory(tsyringeFactory);
    } else if (provider.lifecycle === Lifecycle.ContainerScoped) {
      tsyringeFactory = tsyringe.instancePerContainerCachingFactory(tsyringeFactory);
    }

    this.container.register<T>(token, {
      useFactory: tsyringeFactory,
    });
  }
}
