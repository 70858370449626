import { useInjection } from 'libs/uikit/ioc/React';
import {
  Button,
  Callout,
  ContentLoadingIndicator,
  ExternalLink,
  Flex,
  Grid,
  GridSpan,
  Paragraph,
  Text,
  Title,
  useAsync,
} from 'libs/uikit/lib';
import React from 'react';
import { useParams } from 'react-router-dom';
import { AppShell } from '../../Components/AppShell';
import { ContentContainer } from '../../Components/ContentContainer';
import { DashboardCard } from '../../Components/DashboardCard';
import { EventManagementMenu } from '../../Components/EventManagementMenu';
import { EventTitleBar } from '../../Components/EventTitleBar';
import { ExhibitorEventsClient } from '../../Modules/Api/Clients';
import { toZonedDateTime } from '../../Util/ToZonedDateTime';

export function EventDashboardPage() {
  const { id } = useParams();

  const api = useInjection(ExhibitorEventsClient);

  const marketplaceDetails = useAsync(async () => {
    return await api.getMarketplaceDetails({
      // TODO: how will we manage ambiant information like this
      exhibitorId: 'a7be337c-4d3a-42be-a3f6-c6e4a786c5f2',
      marketplaceId: id,
    });
  });

  const marketplaceStats = useAsync(async () => {
    return await api.getMarketplaceStats({
      // TODO: how will we manage ambiant information like this
      exhibitorId: 'a7be337c-4d3a-42be-a3f6-c6e4a786c5f2',
      marketplaceId: id,
    });
  });

  return (
    <AppShell menu={<EventManagementMenu />}>
      <ContentContainer>
        <ContentLoadingIndicator loading={[marketplaceDetails, marketplaceStats]}>
          <Flex gap={2} align="stretch">
            {marketplaceDetails.data?.details && (
              <EventTitleBar
                name="Example Event"
                startsAt={
                  toZonedDateTime(marketplaceDetails.data.details.startsAt, marketplaceDetails.data.details.timezone)!
                }
                endsAt={
                  toZonedDateTime(marketplaceDetails.data.details.endsAt, marketplaceDetails.data.details.timezone)!
                }
                variant="large"
                image={marketplaceDetails.data.details.image}
              />
            )}

            <Grid gap={2} cols={{ xs: 1, md: 2, xl: 6 }}>
              <GridSpan span={{ xs: 1, md: 2, xl: 4 }}>
                <DashboardCard align="center">
                  <Title size="h6" bold uppercase>
                    Capture leads with LeadGen
                  </Title>
                  <Text>...</Text>
                  <Button>Learn more</Button>
                </DashboardCard>
              </GridSpan>

              <GridSpan span={{ xs: 1, md: 2, xl: 2 }}>
                <DashboardCard
                  align="center"
                  chip={!marketplaceStats.data?.leadStats?.leadsCaptured ? 'not available' : undefined}
                >
                  <Title size="h6" bold uppercase>
                    Leads
                  </Title>
                  <DashboardCardStat stat={marketplaceStats.data?.leadStats?.leadsCaptured} />
                  <Text scale={-1}>leads captured</Text>
                </DashboardCard>
              </GridSpan>

              <GridSpan span={{ xs: 1, md: 2, xl: 2 }}>
                <DashboardCard
                  align="center"
                  chip={!marketplaceStats.data?.credentialStats ? 'not available' : undefined}
                >
                  <Title size="h6" bold uppercase>
                    Credentials
                  </Title>
                  <DashboardCardStat
                    stat={
                      marketplaceStats.data?.credentialStats
                        ? new Fraction(
                            marketplaceStats.data.credentialStats.credentialsConsumed,
                            marketplaceStats.data.credentialStats.credentialsAllocated
                          )
                        : undefined
                    }
                  />
                  <Text scale={-1}>allocated credentials used</Text>
                </DashboardCard>
              </GridSpan>

              <GridSpan span={{ xs: 1, md: 2, xl: 2 }}>
                <DashboardCard align="center" chip={!marketplaceStats.data?.ticketStats ? 'not available' : undefined}>
                  <Title size="h6" bold uppercase>
                    Tickets
                  </Title>
                  <DashboardCardStat stat={marketplaceStats.data?.ticketStats?.ticketsOrdered} />
                  <Text scale={-1}>total tickets ordered</Text>
                </DashboardCard>
              </GridSpan>

              <GridSpan span={{ xs: 1, md: 2, xl: 2 }}>
                <DashboardCard
                  align="center"
                  chip={!marketplaceStats.data?.ticketOptionStats?.optionsRedeemed ? 'not available' : undefined}
                >
                  <Title size="h6" bold uppercase>
                    Options
                  </Title>
                  <DashboardCardStat stat={marketplaceStats.data?.ticketOptionStats?.optionsRedeemed} />
                  <Text scale={-1}>options redeemed</Text>
                </DashboardCard>
              </GridSpan>
            </Grid>

            <DashboardCard>
              <Flex gap={2} align="stretch">
                <Flex gap={1}>
                  <Title size="h6" bold uppercase>
                    Event details
                  </Title>
                  {marketplaceDetails.data?.details?.description && (
                    <Paragraph>{marketplaceDetails.data.details.description}</Paragraph>
                  )}
                </Flex>

                <Flex gap={1}>
                  <Title size="h6" uppercase>
                    Location
                  </Title>
                  {marketplaceDetails.data?.details?.location?.venueName && (
                    <Text>{marketplaceDetails.data?.details?.location?.venueName}</Text>
                  )}
                  {marketplaceDetails.data?.details?.location?.address && (
                    <Text>
                      {marketplaceDetails.data.details.location.address}{' '}
                      <Text muted scale={-1}>
                        <ExternalLink
                          href={`https://maps.google.com/?q=${encodeURIComponent(
                            marketplaceDetails.data.details.location.address
                          )}`}
                        >
                          google maps
                        </ExternalLink>
                      </Text>
                    </Text>
                  )}
                </Flex>

                <Flex gap={1}>
                  <Title size="h6">Organizer</Title>
                  {marketplaceDetails.data?.details?.organizerContactInformation?.name && (
                    <Callout
                      intent="primary"
                      title={marketplaceDetails.data.details.organizerContactInformation.name}
                      subtitle={marketplaceDetails.data.details.organizerContactInformation.email}
                    />
                  )}
                </Flex>
              </Flex>
            </DashboardCard>
          </Flex>
        </ContentLoadingIndicator>
      </ContentContainer>
    </AppShell>
  );
}

interface DashboardCardStatProps {
  stat: number | string | Fraction | undefined;
}

function DashboardCardStat(props: DashboardCardStatProps) {
  if (props.stat instanceof Fraction) {
    return (
      <Flex horizontal gap={0.5} align="baseline">
        <Title superBold size="h1">
          {props.stat.numerator}
        </Title>
        <Title superBold muted size="h6">
          /
        </Title>
        <Title superBold muted size="h6">
          {props.stat.denominator}
        </Title>
      </Flex>
    );
  }

  return (
    <Title superBold size="h1">
      {props.stat ?? '-'}
    </Title>
  );
}

class Fraction {
  constructor(
    public numerator: number,
    public denominator: number
  ) {}
}
