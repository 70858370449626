import { DrawerGroup, DrawerSpacer } from 'libs/uikit/lib';
import React from 'react';
import { useParams } from 'react-router-dom';
import { RouteDrawerItem } from './RouteDrawerItem';

export function EventManagementMenu() {
  const { id } = useParams();

  return (
    <>
      <RouteDrawerItem label="Dashboard" to={`/events/${id}`} />
      <RouteDrawerItem label="Tickets" to={`/events/${id}/tickets`} />
      <DrawerSpacer />
      <DrawerGroup label="Credentials">
        <RouteDrawerItem label="LeadGen" to={`/events/${id}/leadgen`} />
        <RouteDrawerItem label="Traffic Builder" to={`/events/${id}/traffic-builder`} />
      </DrawerGroup>
      <DrawerSpacer />
      <DrawerGroup label="Credentials">
        <RouteDrawerItem label="Manage credentials" to={`/events/${id}/credentials`} />
        <RouteDrawerItem label="Order credentials" to={`/events/${id}/credentials/order`} />
      </DrawerGroup>
      <DrawerSpacer />
      <DrawerGroup label="Manage">
        <RouteDrawerItem label="Integrations" to={`/events/${id}/integrations`} />
      </DrawerGroup>
    </>
  );
}
